p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

h1 {
    font-size: 28px;
}

h2 {
    font-size: 25px;
}

h2.regular {
    font-family: 'Gilroy';
}

h3 {
    font-size: 18px;
}

p {
    font-size: 18px;
}
.p3 {
  font-size: 16px;
}

.p1 {
    font-size: 14px;
}

.p2 {
    font-size: 14px;
}

.subtitle {
    font-size: 13px;
}
