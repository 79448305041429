// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import "react-dates/lib/css/_datepicker.css";
// variables list
@import "assets/scss/variables.scss";
// general styles for main elements
@import "assets/scss/general.scss";
// text p-h1 styles
@import "assets/scss/text.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Lato:wght@300;400&family=Open+Sans&family=Oswald&family=Roboto:wght@300&display=swap');
/* Gilroy Font Generated By: http://www.cufonfonts.com */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

@import "assets/scss/global-components";

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Bold'), url('assets/fonts/Gilroy/Gilroy-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Heavy'), url('assets/fonts/Gilroy/Gilroy-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Light'), url('assets/fonts/Gilroy/Gilroy-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Medium'), url('assets/fonts/Gilroy/Gilroy-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gilroy-Regular'), url('assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
}

// toggle, hover menu
.toggle, .hover {
  display: inline-block;
  position: relative;
  cursor: pointer;

  .menu {
    z-index: 2;
    position: absolute;
    right: 0;
    padding: 0;
    margin: 0;
    display: none;
    width: 130px;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

    li {
      list-style: none;
      white-space: pre-line;
      cursor: pointer;
      //padding: 14px 0;
      padding: 20px 16px;
      color: black;
      transition-duration: 0.2s;

      &:hover {
        background: $purple-500;
      }
    }
  }

  .act-buttons {
    display: none;
  }
}

.hover {
  &:hover .menu {
    display: block;
  }

  &:hover .act-buttons {
    display: flex;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.multiselect-dropdown {
  .dropdown-btn {
    border: 1px solid $black-500 !important;

    .dropdown-down, .dropdown-up {
      border-left: 5px solid transparent !important;
      border-right: 5px solid transparent !important;
    }

    .dropdown-down {
      border-top: 5px solid $black-500 !important;
    }

    .dropdown-up {
      border-bottom: 5px solid $black-500 !important;
    }
  }
}

.mat-input-element {
  padding: 6px 12px !important;
  border: 1px solid $black-500 !important;
  border-radius: 4px !important;
  width: 100% !important;;
}


.mat-select {
  padding: 6px 12px !important;
  border: 1px solid $black-500 !important;
  border-radius: 4px !important;
  font-family: 'Gilroy-Medium' !important;
}

.mat-select-arrow {
  color: $black-500;
}

.mat-checkbox-frame {
  border: 1px solid $black-500 !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-checkbox-checked.mat-accent
.mat-checkbox-background {
  background-color: $black-500 !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
  border: 1px solid $black-500 !important;
  background: #fff !important;
  width: 15px !important;
  height: 15px !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:after {
  border-color: $black-500 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid $black-500 !important;
  background: $black-500 !important;

  a {
    color: #fff !important;
  }
}

textarea {
  width: 100%;
  border: 1px solid $black-500 !important;
  padding: 10px;
  height: 150px;
}

.apexcharts-toolbar {
  top: -20px !important;
}

//.accordion-test {
//  margin: 0 !important;
//  border: none !important;
//  //height: 70px !important;
//}
.heading {
  //height: 25px;
}

accordion-group {
  ::ng-deep {
    div {
      & > div.panel-heading.card-header.panel-enabled {
        background-color: #fafafc !important;
      }

      & > div.panel-collapse.collapse.in.show > div {
        background-color: green; // change the expanded content style
      }
    }

  }
}

.multiselect-dropdown {
  .dropdown-list {
    .item2 {
      max-height: 20vh !important;
    }
  }
}

.dropdown-list {
  overflow: hidden !important;
}

.popup-block {
  .multiselect-dropdown {
    .dropdown-list {
      margin-top: 0 !important;
    }
  }
}

.spinner-block {
  position: relative;
  z-index: 1;
  height: 100%;

}

.block-title {
  height: 20%;
  display: flex;
  max-height: 55px;
  align-items: center;
}

.block-content {
  //height: 80%;
  max-height: 84%;
  overflow: auto;
}

.dropdown-btn {
  span {
    padding-right: 10px !important;
  }

  .selected-item {
    padding-right: 7px !important;
    margin-right: 20px !important;
  }
}

.resp-title-md {
  h2 {
    font-size: 16px;
  }
}

.resp-title-lg {
  h2 {
    font-size: 16px;
  }
}

.spot-button {
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  padding: 7px 15px;
  transition-duration: 0.3s;


  img {
    margin-right: 15px;
  }
}

.spot-primary-button {
  background: $primary-button-background;
  color: $button-color;

  &:hover {
    background: $primary-button-background-hover;
    cursor: pointer;
  }
}

.spot-secondary-button {
  background: $secondary-button-background;
  color: #6E67E8;

  &:hover {
    background: $secondary-button-background-hover;
    cursor: pointer;
  }
}

.spot-danger-button-gray {
  background: $danger-btn-background-gray;
  color: #FF7788;
  cursor: pointer;
}

.spot-light-button {
  background: $light-button-background;
  color: $purple-700;

  &:hover {
    background: $light-button-background-hover;
    cursor: pointer;
  }
}

.spot-cancel-button {
  background: $danger-btn-background;
  color: #FF7788;

  &:hover {
    //background: #fa8d99;
    cursor: pointer;
  }
}

.op-table {
  thead {
    background-color: $operations-table-background;

    th {
      color: $black-700;
      font-weight: 600;
    }
  }

  tbody {
    td {
      border-bottom: none !important;
    }

    .odd {
      background-color: $black-50;


    }
  }
}

.table-heading {
  height: 69px;
  max-height: 69px;
}

.operation-table-pagination {
  background: $black-50;

  .right-pagination {
    img {
      border: 1px solid $purple-900;
    }
  }
}

.table-heading .right-table-menu {
  input {
    margin-right: 10px;
  }

  .close-search {
    right: 120px !important;
  }
}

@media screen and (max-width: 1100px) {

  .resp-title-md {
    h2 {
      //font-size: 22px !important;
    }
  }

  .resp-title-lg {
    h2 {
      //font-size: 22px !important;
    }
  }
}

@media screen and (max-width: 450px) {
  .resp-title-md {
    h2 {
      //font-size: 19px !important;
    }
  }
}

@media screen and (max-width: 400px) {
  .resp-title-md {
    h2 {
      //font-size: 18px !important;
    }
  }
}
