$black-900: #31303C;
$black-900-2: #131313;
$black-700: #747380;
$black-500: #B4B2C1;
$black-300: #EFEDFD;
$black-100: #FAFAFC;

$green-dark: #365D3A;
$green-yellow: #CDD646;
$green-blue: #29abe2;
$green-2: #27AE60;

$blue: #508EAB;
$light-blue: #A4D0E7;

$red: #FC9199;
$purple: #A4A4FF;

//  --- new design start
$purple-500: #f3ecfe;
$purple-900: #6B60EA;
$purple-700: #807be2;
$purple-900-hover: #554cbe;

$button-color: #fff;

$primary-button-background: #6B60EA;
$primary-button-background-hover: #5d53cb;
$secondary-button-background: #D5D5D5;
$secondary-button-background-hover: #c1c1c1;
$light-button-background: #D2CFF8;
$light-button-background-hover: #e7e6f7;

$danger-btn-background: rgba(250, 128, 144, 0.25);
$danger-btn-background-gray: #D5D5D5;
$operations-table-background: #f3f3f3;
$black-50: #FAFAFC;
$black-350: #D1D1D1;
$black-150: #EEEEEE;
//  --- new design end

$orange: #FAC740;
// need to remove
$red-color: #cc1100;
$dashboard-background: #fafafc;
$dashboard-wrapper-background: #ffffff;
$dialog-background: #EEEEEE;

// * chart colors
$chart-gray: #CFC7F7;
$chart-blue: #6B60EA;
$chart-green: #27C86A;
