* {
    padding: 0;
    margin: 0;
}

input[type="checkbox"] {
  vertical-align: inherit;
  margin: 0;
  color: $black-500;
}

a {
    text-decoration: none;
    color: $black-500;
    outline: none;
}

ul {
  text-decoration: none;
  list-style: none;
}


body {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    background-color: #ffffff;
    overflow: hidden;
}

* {
    scrollbar-width: thin;
    scrollbar-color: $black-500 $black-300;
  }
  *::-webkit-scrollbar {
    width: 8px;
  }
  *::-webkit-scrollbar-track {
    background: $black-300;
  }
  *::-webkit-scrollbar-thumb {
    background-color: $black-500;
    border-radius: 20px;
    border: 2px solid $black-300;
  }

  .badge {
    font-size:1.4rem;
    padding: 1rem;
  }
