/*************************************** TABLE ***************************************/

.seigos-table {
  border-collapse: collapse;
  width: 100%;
  background-color: transparent;
  border-spacing: 0;

  &.table-fixed {
    table-layout: fixed;
  }

  .seigos-thead {
    width: 100%;
    background-color: #f3f3f3;
  }

  .seigos-tbody {
    max-height: 100%;
    height: 100%;
    width: 100%;
    //display: block;
    overflow-y: scroll;
  }

  .seigos-tr {
    //display: table;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    transition-duration: 0.2s;

    &:nth-child(2n) {
      background-color: #fafafc;
    }
    &:hover {
      background-color: #f3ecfe;
    }

    .seigos-th {
      color: #747380;
      font-weight: 600;
      border-bottom: 1px solid #dddddd;
      text-align: left;
      padding: 8px;

      &.seigos-checkbox-cell {
        //width: calc(5%);
        //padding-left: 25%;
      }

      &.seigos-ordering {
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;

          .asc, .desc {
            display: block;
            opacity: 0.5;
          }

          .asc {
            transform: rotate(180deg);
          }

          .desc {
            margin-top: 2px;
          }

          img.active {
            opacity: 1;
          }
        }
      }

      .seigos-filter {
        display: block;
        width: 190px !important;
        z-index: 2;
        position: absolute;
        padding: 0;
        margin: 0;
        background: white;

        &:focus {
          display: block;
        }

        li {
          list-style: none;
          white-space: pre-line;
          cursor: pointer;
          padding: 20px 16px;
          color: black;

          &:hover {
            background-color: #f3ecfe
          }
        }
      }
    }

    .seigos-td {
      border-bottom: none !important;
      text-align: left;
      padding: 20px 9px;

      &.seigos-checkbox {
        //width: calc(5%);
        //padding-left: 25%;
      }
    }
  }
}

.seigos-table-count {
  color: #747380;
  font-size: 1.5rem;

}

/*************************************** END TABLE ***************************************/
